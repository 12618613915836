<template>
    <!--TODO: implement exec astp_Arena_DocumentSyncProcessParticipants (show if some prop or calculation = true if a process is ongoing)-->
    <div class="row">
        <OFormControl title="Review Responsible" class="col-12 col-xl-3">
            <ODataLookup :data-object="dsLocal_PersonsWithAccess"
                openonfocus
                :bind="sel=> { props.dataObject.current.ReviewResponsible_ID=sel.ID; props.dataObject.current.ReviewResponsible=sel.Name;}"
                class="form-control form-control-sm"
                :height="400"
                :value="props.dataObject.current.ReviewResponsible">
                <OColumn name="ID" width="80"/> 
                <OColumn name="Name" width="300"/>
            </ODataLookup>
        </OFormControl>
        <OFormControl title="IDC Responsible" class="col-12 col-xl-3" v-if="IDCEnabled">
            <ODataLookup :data-object="dsLocal_PersonsWithAccess"
                openonfocus
                :bind="sel=> { props.dataObject.current.IDCResponsible_ID=sel.ID; props.dataObject.current.IDCResponsible=sel.Name;}"
                class="form-control form-control-sm"
                :height="400"
                :value="props.dataObject.current.IDCResponsible">
                <OColumn name="ID" width="80"/> 
                <OColumn name="Name" width="300"/>
            </ODataLookup>
        </OFormControl>
    </div>

    <DistSetupPersons :dataObject="props.dataObject" ref="DistSetupPersonsRef" class="mt-3"></DistSetupPersons>
    <DistSetupOrgUnits :dataObject="props.dataObject" ref="DistSetupOrgUnitsRef" class="mt-3"></DistSetupOrgUnits>
    <DistSetupGroups :dataObject="props.dataObject" ref="DistSetupGroupsRef" @insertDelete="refreshGrids" class="mt-3 mb-1"></DistSetupGroups>
    <PublishedToOrgUnits :dataObject="props.dataObject" class="mt-3"/> 
</template>

<script setup>
    import { ref } from 'vue';
    import { getOrCreateDataObject } from 'o365-dataobject'
    import DistSetupPersons from 'arena.vue.components.DistributionSetupPersons.vue'
    import DistSetupOrgUnits from 'arena.vue.components.DistributionSetupOrgUnits.vue'
    import DistSetupGroups from 'arena.vue.components.DistributionSetupGroups.vue'
    import PublishedToOrgUnits from 'arena.vue.components.DistributionPublishToOrgUnits.vue'
    import { useDataObjectEventListener } from "o365-vue-utils";

    const props = defineProps({
        dataObject: Object
    })

    const DistSetupPersonsRef = ref(null),  DistSetupOrgUnitsRef = ref(null),  DistSetupGroupsRef = ref(null);
    const IDCEnabled = ref(false)

    const personsWithAccessDatasourceDefinition = {
        id: 'dsLocal_PersonsWithAccess',
        viewName: 'aviw_Arena_PersonsWithAccess',
        appId: props.dataObject.appId,
        maxRecords: 100,
        loadRecents: true,
        distinctRows: true,
        disableAutoLoad: true,
        masterDataObject_ID: props.dataObject.id,
        masterDetailDefinition: [{
            detailField: "Document_ID",
            masterField: "ID",
            operator: "equals"
        }],
        fields:
            [
                {name: "ID", type: "number"},
                {name: "Name", type: "string"},
                {name: "Document_ID", type: "string"}
            ]
    }

    const dsSettingsDef = {
        id: 'dsLocal_Settings',
        viewName: 'atbv_Arena_Settings',
        appId: props.dataObject.appId,
        maxRecords: 100,
        loadRecents: true,
        distinctRows: true,
        disableAutoLoad: true,
        fields:
            [
                {name: "IDCProcessEnabled", type: "bool"}
            ]
    }

    const dsLocal_PersonsWithAccess = getOrCreateDataObject(personsWithAccessDatasourceDefinition);
    const dsLocal_Settings = getOrCreateDataObject(dsSettingsDef);

    dsLocal_Settings.load().then(() => {
        if(dsLocal_Settings.data.length > 0)
            IDCEnabled.value = dsLocal_Settings.data[0].IDCProcessEnabled
    })

    useDataObjectEventListener(props.dataObject, "AfterSave", (pData) => {
        if ("DeliveryVerified" in pData.values || "RevisionDate" in pData.values || "ReleaseCode_ID" in pData.values) {
            DistSetupGroupsRef.value.refreshGrid();
            refreshGrids();
        }
    });

    function refreshGrids(){
        DistSetupPersonsRef.value.refreshGrid();
        DistSetupOrgUnitsRef.value.refreshGrid();
    }
</script>